@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Poppins', sans-serif;
}

body{
  background:#000;
}

h1,h4,p,a{
  color:#fff;
  text-decoration: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #00FFFF;
  color:#222;
  border:1px solid #fff;
  font-weight: 600;
  cursor:pointer;
}

.btn-light{
  background: transparent;
  color:#fff;
}

ul{
  list-style-type: none;
}

.btn:hover{
  background: rgba(255,255,255,0.2);
  color:#fff;
  transition: 0.3s;
}