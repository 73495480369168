.hero-img{
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    height: 60vh;
    position:relative;
}

.hero-img::before{
    content:"";
    background: url("https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg?w=1380&t=st=1693054706~exp=1693055306~hmac=6ba287c36d5bf40a5594407a46f55d1276dc4ea7516e75e490e61a303f303e9e");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position:absolute;
    top:0;
    left:0;
    z-index:-1;
}

.heading{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.hero-img h1{
    font-size: 2.4rem;
}

.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width:640px){
    .hero-img h1{
        font-size: 2rem;
    }
}